/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';
import { css } from '@emotion/react';
import Input from 'components/common/Input';
import { useRecoilState } from "recoil";
import { assetState } from "recoil/Atoms";


const StockInfo = ({ title, content }) => (
  <div>
    <span>{title}</span>
    <em>{content}</em>
  </div>
);

const WriteStageTwo = ({ setStage }) => {
  const [asset, setAsset] = useRecoilState(assetState);
  const [buildingName, setBuildingName] = useState('');
  const [address, setAddress] = useState('');
  const [detailAddress, setDetailAddress] = useState('');
  const [requestPrice, setRequestPrice] = useState(0);
  const [landAreaExp, setLandAreaExp] = useState(0);
  const [buildingAreaExp, setBuildingAreaExp] = useState(0);
  const [buildingAreaPub, setBuildingAreaPub] = useState(0);

  useEffect(() => {
    if (asset && Object.keys(asset).length > 0) {
      console.log("Recoil assetState 존재:", asset);

      if (asset.name) setBuildingName(asset.name);
      if (asset.address) setAddress(asset.address);
      if (asset.detailAddress) setDetailAddress(asset.detailAddress);
      if (asset.requestPrice) setRequestPrice(asset.requestPrice);
      if (asset.spec.landAreaExp) setLandAreaExp(asset.spec.landAreaExp);
      if (asset.spec.buildingAreaExp) setBuildingAreaExp(asset.spec.buildingAreaExp);
      if (asset.spec.buildingAreaPub) setBuildingAreaPub(asset.spec.buildingAreaPub);
    } else {
      console.log("Recoil assetState 존재하지 않음");
    }
  }, [asset]);


  const handleNextButtonClick = (nextYn) => {
    setAsset({
      ...asset,
      name: buildingName,
      address: address,
      detailAddress: detailAddress,
      requestPrice: requestPrice,
      spec: {
        ...asset.spec,
        landAreaExp: landAreaExp,
        buildingAreaExp: buildingAreaExp,
        buildingAreaPub: buildingAreaPub,
      },
    });

    if (nextYn) {
      setStage(3);
    } else {
      alert('임시저장 되었습니다.');
    }

  };

  return (
    <div css={stageTwoWrapStyle}>
      <div css={leftSideStyle}>
        <div css={stockInfoWrapStyle}>
          <StockInfo title="증권 형태" content="수익증권"/>
          <StockInfo title="증권 종류" content="부동산 자산"/>
        </div>
      </div>
      <div css={rightSideStyle}>
        <span>STEP2. 부동산 자산의 기본 정보를 입력해주세요.</span>
        <div
          css={css`
            ${inputBoxStyle};
            margin-top: 80px;
          `}
        >
          <Input label="건물 이름" width={500} placeholder="발행할 건물의 이름을 입력해주세요." value={buildingName}
                 onChange={(e) => setBuildingName(e.target.value)}/>
        </div>
        <div css={inputBoxStyle}>
          <Input label="주소" width={500} placeholder="발행할 건물의 주소를 입력해주세요." value={address}
                 onChange={(e) => setAddress(e.target.value)}/>
          <Input width={500} placeholder="상세 주소를 입력해주세요." value={detailAddress}
                 onChange={(e) => setDetailAddress(e.target.value)} padding={0}/>
        </div>
        <div css={inputBoxStyle}>
          <Input label="예상 매수 금액" width={500} placeholder="발행할 건물의 예상 매수금액을 입력해주세요." unit="원" value={requestPrice}
                 onChange={(e) => setRequestPrice(e.target.value)}/>
        </div>
        <div css={inputBoxStyle}>
          <Input label="대지면적(전유)" width={500} placeholder="발행할 건물의 전유 대지면적을 입력해주세요." unit="㎡" value={landAreaExp}
                 onChange={(e) => setLandAreaExp(e.target.value)}/>
        </div>
        <div
          css={css`
            ${inputBoxStyle};
            display: flex;
            flex-direction: row;
            gap: 40px;
          `}
        >
          <Input label="연면적(전유)" width={320} placeholder="전유 연면적을 입력해주세요." unit="㎡" value={buildingAreaExp}
                 onChange={(e) => setBuildingAreaExp(e.target.value)}/>
          <Input label="연면적(공유면적 포함)" width={320} placeholder="공유면적을 포함한 전유면적을 입력해주세요." unit="㎡" value={buildingAreaPub}
                 onChange={(e) => setBuildingAreaPub(e.target.value)}/>
        </div>
        <div css={btnWrapStyle}>
          <div css={btnPrimaryStyle} onClick={() => {
            handleNextButtonClick(0)
          }}>임시저장
          </div>
          <div css={btnSecondaryStyle} onClick={() => {
            handleNextButtonClick(1)
          }}>
            다음
          </div>
        </div>
      </div>
    </div>

  );
};

const stageTwoWrapStyle = css`
  width: 100%;
  color: white;
  display: flex;
  flex-direction: row;
  padding: 50px 0 0 0;
  margin-left: 100px;

  span {
    font-size: 18px;
  }
`;

const leftSideStyle = css`
  width: 450px;
`;

const stockInfoWrapStyle = css`
  display: flex;
  flex-direction: column;
  gap: 30px;

  div {
    display: flex;
    flex-direction: column;

    span {
      color: #b3b3b3;
      font-size: 12px;
      margin-bottom: 10px;
    }

    em {
      font-style: normal;
      font-size: 15px;
    }
  }
`;

const rightSideStyle = css`
  width: 100%;
`;

const inputBoxStyle = css`
  margin-bottom: 40px;
`;

const btnWrapStyle = css`
  display: flex;
  flex-direction: row;
  justify-content: center;
  color: white;
  gap: 10px;
  margin-top: 20px;
  cursor: pointer;
`;

const btnStyle = css`
  padding: 20px 30px;
  border-radius: 10px;
  width: 60px;
  text-align: center;
`;

const btnPrimaryStyle = css`
  ${btnStyle};
  background-color: rgba(44, 57, 71, 1);
`;

const btnSecondaryStyle = css`
  ${btnStyle};
  background-color: rgba(49, 111, 206, 1);
`;

export default WriteStageTwo;
