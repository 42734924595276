/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';
import { css } from '@emotion/react';

function Select({ label, options, value, onChange, width }) {

  const [color, setColor] = useState('#7c7c7c');

  const handleOnChange = (e) => {
    onChange(e);
    setColor(e.target.value === '' ? '#7c7c7c' : '#FFFFFF');
  }

  const inputWrapStyle = css`
    padding: 10px 0;
  `;

  const selectStyle = css`
    border-radius: 10px;
    background-color: #323b51;
    padding-left: 10px;
    color: ${color}; // 상태에 따라 색상을 설정합니다.
    width: ${width}px;
    height: 50px;
    font-size: 17px;

    &:focus {
      outline: none;
    }
  `;


  const labelStyle = css`
    color: #ffffff;
    font-size: 18px;
    margin-bottom: 10px;
    display: block;
  `;

  useEffect(() => {
    if (value) {
      setColor('#FFFFFF');
    } else {
      setColor('#7C7C7C');
    }
  }, [value]);

  return (
    <div css={inputWrapStyle}>
      {label && <label css={labelStyle}>{label}</label>}
      <select css={selectStyle} value={value || ''} onChange={handleOnChange}>
        <option value="" disabled>
          선택하세요
        </option>
        {options.map((option, index) => (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
}

export default Select;
