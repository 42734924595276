/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';
import { css } from '@emotion/react';
import { useRecoilState } from 'recoil';
import { assetState } from 'recoil/Atoms';
import Select from "components/common/Select";

const WriteStageOne = ({ setTokenType, setStage }) => {
  const [selectedType, setSelectedType] = useState(null);
  const [selectedDetailType, setSelectedDetailType] = useState(null);
  const [asset, setAsset] = useRecoilState(assetState);

  const updateSelectedTypes = (typeCode) => {
    setSelectedDetailType(typeCode);
    if (["realEstate", "ship"].includes(typeCode)) {
      setSelectedType('A');
    } else if (["art", "music", "copyright"].includes(typeCode)) {
      setSelectedType('B');
    }
  };

  useEffect(() => {
    if (asset.typeCode) {
      updateSelectedTypes(asset.typeCode);
    }
  }, [asset]);

  const btnSecondaryStyle = css`
    background-color: ${selectedDetailType ? 'rgba(49, 111, 206, 1)' : '#7C7C7C'};
    padding: 20px 30px;
    border-radius: 10px;
    cursor: ${selectedDetailType ? 'pointer' : 'not-allowed'};
  `;


  const handleSelectType = (type) => {
    setSelectedType(type);
    setTokenType(type);
    setSelectedDetailType('');
  };

  const renderSelectOptions = () => {
    switch (selectedType) {
      case 'A':
        return (
          <>
            <option value="realEstate">부동산자산</option>
            <option value="ship">선박 자산</option>
          </>
        );
      case 'B':
        return (
          <>
            <option value="art">미술품</option>
            <option value="music">음원</option>
            <option value="copyright">저작권</option>
          </>
        );
      default:
        return null;
    }
  };

  const handleNextButtonClick = (nextYn) => {
    setAsset((prevAsset) => {
      const newAsset = { ...prevAsset, typeCode: selectedDetailType };
      return newAsset;
    });

    if (nextYn) {
      setStage(2);
    } else {
      alert('임시저장 되었습니다.');
    }
  };

  return (
    <div css={stageOneWrapStyle}>
      <h3
        css={css`
          margin-left: 90px;
        `}
      >
        STEP1. 발행할 증권의 형태를 선택해주세요.
      </h3>
      <div css={typeSelectorWrapStyle}>
        <div css={typeSelectorStyle(selectedType === 'A')} onClick={() => handleSelectType('A')}>
          <span>수익증권</span>
          <em>부동산, 선박 등</em>
        </div>
        <div css={typeSelectorStyle(selectedType === 'B')} onClick={() => handleSelectType('B')}>
          <span>투자계약증권</span>
          <em>미술품, 음원, 저작권 등</em>
        </div>
      </div>
      <div css={selectBoxWrapStyle(selectedType)}>
        <label css={labelStyle} htmlFor="securityType">
          {selectedType === 'A' ? '수익증권 종류' : '투자계약증권 종류'}
        </label>
        <Select
          id="securityType"
          options={selectedType === 'A' ? [{ label: '부동산자산', value: '부동산자산' }, {
            label: '선박자산',
            value: '선박자산'
          }] : [{ label: '미술품', value: '미술품' }, { label: '음원', value: '음원' }, { label: '저작권', value: '저작권' }]}
          value={selectedDetailType || ''}
          onChange={(e) => setSelectedDetailType(e.target.value)}
          width={200}
          disabled={!selectedType}
        />
      </div>
      <div css={btnWrapStyle(selectedType)}>
        <div css={btnPrimaryStyle} onClick={() => {
          handleNextButtonClick(0)
        }}>취소
        </div>
        <div css={btnSecondaryStyle} onClick={() => {
          if (selectedDetailType) {
            handleNextButtonClick(1);
          }
        }}>
          다음
        </div>
      </div>
    </div>
  );
};

const stageOneWrapStyle = css`
  width: 100%;
  color: white;
`;

const typeSelectorWrapStyle = css`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 80px 0;
  gap: 50px;
`;

const typeSelectorStyle = (isSelected) => css`
  display: flex;
  flex-direction: column;
  width: 130px;
  padding: 30px 80px;
  background-color: ${isSelected ? 'rgba(217, 217, 217, 0.15)' : 'rgba(100, 100, 100, 0.15)'};
  border: 2px solid ${isSelected ? 'white' : 'transparent'};
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s,
  border 0.3s;

  span {
    font-size: 20px;
    color: ${isSelected ? 'white' : 'rgba(179, 179, 179, 1)'};
  }

  em {
    font-style: normal;
    font-size: 14px;
    color: ${isSelected ? 'rgba(179, 179, 179, 1)' : 'rgba(140, 140, 140, 1)'};
    margin-top: 20px;
  }
`;

const btnWrapStyle = (selectedType) => css`
  display: ${selectedType ? 'flex' : 'none'};
  flex-direction: row;
  justify-content: right;
  color: white;
  gap: 10px;
  margin-top: 20px;
  margin-right: 450px;
  cursor: pointer;
`;

const btnPrimaryStyle = css`
  background-color: rgba(44, 57, 71, 1);
  padding: 20px 30px;
  border-radius: 10px;
`;


const selectBoxWrapStyle = (selectedType) => css`
  display: ${selectedType ? 'flex' : 'none'};
  flex-direction: column;
  margin-left: 600px;
  width: 300px;
`;

const selectBoxStyle = css`
  width: 200px;
  background-color: rgba(213, 213, 213, 1);
  border: 1px solid #7d7d7d;
  padding: 10px 20px;
  margin-top: 5px;
  border-radius: 5px;
  color: rgba(44, 57, 71, 1);
  font-size: 16px;
`;

const labelStyle = css`
  display: block;
  color: white;
`;

export default WriteStageOne;
