import axios from 'axios';

// const API_BASE_URL = 'http://192.168.151.36:9800/api/v1';
const API_BASE_URL = 'https://dev-sto-api.funble.kr/api/v1';
const ApiService = {
  getInfo: async (key) => {
    try {
      let url = `${API_BASE_URL}/getInfo`;
      if (key) {
        url += `?key=${key}`;
      }
      const response = await axios.get(url);
      return response.data;
    } catch (error) {
      console.error('There was an error!', error);
      throw error;
    }
  },

  insertAsset: async (assetData, userData) => {
    try {
      const payload = {
        user: {
          name: userData.name,
          id: userData.id,
          address: userData.address,
          email: userData.email
        },
        asset: {
          typeCode: "REAL_ESTATE",
          typeName: assetData.typeName,
          name: assetData.name,
          address: assetData.address,
          detailAddress: assetData.detailAddress,
          spec: {
            landAreaExp: assetData.spec.landAreaExp,
            buildingAreaExp: assetData.spec.buildingAreaExp,
            buildingAreaPub: assetData.spec.buildingAreaPub,
            purposeCode: "BUSINESS",
            purposeName: assetData.spec.purposeName
          },
          lease: {
            stdDate: assetData.lease.stdDate,
            expireDate: assetData.lease.expireDate,
            leaseFee: assetData.lease.leaseFee,
            rentalDeposit: assetData.lease.rentalDeposit,
            tenant: assetData.lease.tenant
          },
          requestPrice: assetData.requestPrice
        },
        stock_type: {
          code: "STOCK_01",
          name: "string"
        },
        model_type: {
          code: "BENEFICIARY_CERTIFICATES",
          name: "string"
        }
      };

      const response = await axios.post(`${API_BASE_URL}/insert`, payload);
      return response.data;
    } catch (error) {
      console.error('There was an error!', error);
      throw error;
    }
  },

  downloadStoDocument: async (documentId) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/poi/${documentId}`);
      const fileUrl = response.data;

      const link = document.createElement('a');
      link.href = fileUrl;
      link.setAttribute('download', '');
      document.body.appendChild(link);
      link.click();
      link.remove();

    } catch (error) {
      console.error('There was an error!', error);
      throw error;
    }
  },

};

export default ApiService;
