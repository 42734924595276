/** @jsxImportSource @emotion/react */
import React from 'react';
import { css } from '@emotion/react';
import { useRecoilValue } from 'recoil';
import { tokenListState } from 'recoil/Atoms';

function DashBoard() {
  const tokenList = useRecoilValue(tokenListState);

  const renderTokenList = (item) => {
    return (
      <tr key={item.key}>
        <td>{item.request.asset.name}</td>
        <td>{item.request.asset.lease.expireDate}</td>
        <td>{'토큰총량'}</td>
        <td>{'누적배당금'}</td>
        <td>{'현재가'}</td>
      </tr>
    );
  };

  return (
    <div css={dashboardWrap}>
      {tokenList.length > 0 ? (
        <div css={dashBoardItem}>
          <h3>토큰 발행 현황</h3>
          <table css={tableStyle}>
            <thead>
            <tr>
              <th>토큰이름</th>
              <th>등록일자</th>
              <th>예상공모금액</th>
              <th>예상 수익률</th>
              <th>발행 일정</th>
            </tr>
            </thead>
            <tbody>{tokenList.slice(0, 10).map(renderTokenList)}</tbody>
          </table>
        </div>
      ) : (
        <div css={dashBoardItem}>
          <h3>발행 토큰 현황</h3>
          <p>현재 발행된 토큰이 없습니다.</p>
        </div>
      )}
    </div>
  );
}

const dashboardWrap = css`
  width: 100%;
  height: 100%;
  padding: 30px 20px;
  background: linear-gradient(to top right,
  rgba(0, 0, 0, 1) 0%,
  rgba(4, 4, 6, 1) 25%,
  rgba(15, 26, 54, 1) 50%,
  rgba(22, 38, 64, 0.77) 75%,
  rgba(55, 103, 163, 1) 100%);
`;

const dashBoardItem = css`
  border-radius: 10px;
  padding: 10px 20px;
  background-color: rgba(45, 46, 52, 0.5);
  color: white;
  margin: 15px 5px;
`;

const tableStyle = css`
  width: 100%;
  border-colapse: separate;
  border-spacing: 0 10px;

  th {
    padding: 15px 40px;
    text-align: left;
    font-weight: normal;
    font-size: 12px;
  }

  td {
    padding: 15px 40px;
    background-color: rgba(255, 255, 255, 0.05);
    font-size: 15px;
    font-weight: 400;
  }

  td:first-of-type {
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
  }

  td:last-of-type {
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
  }

  th,
  td {
    width: 20%;
  }
`;

export default DashBoard;
