/** @jsxImportSource @emotion/react */

import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from 'pages/Home';
import { css, Global } from '@emotion/react';
import WriteRegister from 'pages/WriteRegister';
import { RecoilRoot, useRecoilTransactionObserver_UNSTABLE, useSetRecoilState } from "recoil";
import { assetState, tokenListState, userState } from "recoil/Atoms";
import TokenDetail from "components/home/TokenDetail";

const GlobalStyle = () => {
  return (
    <Global
      styles={css`
        html {
          scroll-behavior: smooth;
        }

        body {
          min-width: 1500px;
          font-family: 'Noto Sans', sans-serif;
        }
      `}
    />
  );
};

const PersistenceObserver = () => {
  useRecoilTransactionObserver_UNSTABLE(({ snapshot }) => {
    for (const modifiedAtom of snapshot.getNodes_UNSTABLE({ isModified: true })) {
      const atomLoadable = snapshot.getLoadable(modifiedAtom);
      if (atomLoadable.state === 'hasValue') {
        localStorage.setItem(modifiedAtom.key, JSON.stringify(atomLoadable.contents));
      }
    }
  });
  return null;
};

const InitializeState = () => {
  const setUserState = useSetRecoilState(userState);
  const setAssetState = useSetRecoilState(assetState);
  const setTokenListState = useSetRecoilState(tokenListState);

  useEffect(() => {
    const savedUserState = localStorage.getItem('userState');
    const savedAssetState = localStorage.getItem('assetState');
    const savedTokenListState = localStorage.getItem('tokenListState');

    if (savedUserState != null) {
      setUserState(JSON.parse(savedUserState));
    }
    if (savedAssetState != null) {
      setAssetState(JSON.parse(savedAssetState));
    }
    if (savedTokenListState != null) {
      setTokenListState(JSON.parse(savedTokenListState));
    }
  }, [setUserState, setAssetState, setTokenListState]);

  return null;
};

function App() {
  return (
    <RecoilRoot>
      <PersistenceObserver/>
      <InitializeState/>
      <GlobalStyle/>
      <Router>
        <Routes>
          <Route path="/" element={<Home/>}/>
          <Route path="/write" element={<WriteRegister/>}/>
          <Route path="/detail" element={<TokenDetail/>}/>
        </Routes>
      </Router>
    </RecoilRoot>
  );
}

export default App;
