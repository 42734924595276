/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';
import { css } from '@emotion/react';
import { useRecoilState } from 'recoil';
import { tokenListState } from 'recoil/Atoms';
import SideNav from 'components/home/SideNav';
import DashBoard from 'components/home/DashBoard';
import ApiService from 'services/ApiService'
import TokenList from "components/home/TokenList";
import TokenDetail from "components/home/TokenDetail";
import { useNavigate, useSearchParams } from "react-router-dom";
import SecurityDoc from "components/home/SecurityDoc";

const homeWrapStyle = css`
  display: flex;
  min-height: 900px;
`;

const rightComponentStyle = css`
  flex: 1;
  overflow-y: auto;
  min-height: 900px;
`;

function Home() {
  const [searchParams] = useSearchParams();
  const [viewType, setViewType] = useState('dashboard')
  const [navPick, setNavPick] = useState('dashboard');
  const [tokenList, setTokenList] = useRecoilState(tokenListState);
  const [detailKey, setDetailKey] = useState(null);
  const navigate = useNavigate();

  const components = {
    dashboard: DashBoard,
    status: TokenList,
    detail: TokenDetail,
    doc: SecurityDoc,
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await ApiService.getInfo();
        setTokenList(data);
      } catch (error) {
        console.error('Failed to fetch data:', error);
      }
    };

    fetchData();
  }, [setTokenList]);

  useEffect(() => {
    const typeFromURL = searchParams.get('type');
    const keyFromURL = searchParams.get('key');

    switch (typeFromURL) {
      case 'dashboard':
        setNavPick('dashboard');
        break;
      case 'status':
        setNavPick('status');
        break;
      case 'detail':
        if (keyFromURL) {
          setNavPick('detail');
        }
        break;
      case 'doc':
        if (keyFromURL) {
          setNavPick('doc');
        }
        break;
    }
  }, [searchParams]);

  const handleNavPick = (name) => {
    if (components[name]) {
      setNavPick(name);
    }
  };

  const detailRender = (key) => {
    if (key) {
      navigate('/?type=detail&key=' + key);
    }
  }

  const backToList = () => {
    setNavPick('status')
  }

  const ComponentToRender = components[navPick];

  return (
    <div css={homeWrapStyle}>
      <SideNav setNavPick={handleNavPick} viewType={viewType}/>
      <div css={rightComponentStyle}>
        <ComponentToRender detailKey={detailKey} detailRender={detailRender} backToList={backToList}/>
      </div>
    </div>
  );
}

export default Home;
