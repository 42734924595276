/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';
import { css } from '@emotion/react';
import WriteProgressBar from 'components/publish/WriteProgressBar';
import WriteStageOne from 'components/publish/WriteStageOne';
import WriteStageTwo from 'components/publish/WriteStageTwo';
import WriteStageThree from 'components/publish/WriteStageThree';
import WriteStageDone from 'components/publish/WriteStageDone';
import { useNavigate } from "react-router-dom";

const registerWrap = css`
  width: 100vw;
  min-width: 1500px;
  min-height: 1000px;
  padding: 30px 20px;

  background: linear-gradient(to top right,
  rgba(0, 0, 0, 1) 0%,
  rgba(4, 4, 6, 1) 25%,
  rgba(15, 26, 54, 1) 50%,
  rgba(22, 38, 64, 0.77) 75%,
  rgba(55, 103, 163, 1) 100%);
`;

function WriteRegister() {
  const [completions, setCompletions] = useState([0, 0, 0]);
  const [stage, setStage] = useState(1);
  const [tokenType, setTokenType] = useState(null);
  const [resultKey, setResultKey] = useState('');
  const navigate = useNavigate();


  useEffect(() => {
    window.scrollTo(0, 0);
    switch (stage) {
      case 1:
        setCompletions([0, 0, 0]);
        break;
      case 2:
        setCompletions([100, 0, 0]);
        break;
      case 3:
        setCompletions([100, 100, 0]);
        break;
      default:
        break;
    }
  }, [stage]);

  return (
    <div css={registerWrap}>
      <img
        css={css`
          width: 100px;
          cursor: pointer;
        `}
        src="/funble_logo.png"
        alt="Logo"
        onClick={() => {
          navigate('/')
        }}
      />


      {stage !== 4 ? <WriteProgressBar completions={completions} stage={stage} setStage={setStage} /> : null}
      {stage === 1 ? <WriteStageOne setStage={setStage} setTokenType={setTokenType}/> : null}
      {stage === 2 ? <WriteStageTwo setStage={setStage}/> : null}
      {stage === 3 ? <WriteStageThree setStage={setStage} setResultKey={setResultKey}/> : null}
      {stage === 4 ? <WriteStageDone resultKey={resultKey}/> : null}
    </div>
  );
}

export default WriteRegister;
