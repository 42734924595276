/** @jsxImportSource @emotion/react */
import React, { useEffect } from 'react';
import { css } from '@emotion/react';
import LeftArrow from 'assets/images/write/img_left_arrow.png';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { assetState } from 'recoil/Atoms';

const ProgressItem = ({ number, label, completion, stage, stageChanger }) => (
  <div css={progressItem} onClick={() => {
    stageChanger(number)
  }}>
    <div css={progressTop}>
      <span
        css={css`
          display: inline-flex;
          align-items: center;
          justify-content: center;
          font-style: normal;
          color: white;
          width: 30px;
          height: 30px;
          background-color: rgba(12, 49, 76, 1);
          border-radius: 50%;
          margin-right: 10px;
        `}
      >
        {number}
      </span>
      <h3
        css={css`
          color: ${stage === number ? '#728FD2' : 'white'};
        `}
      >
        {label}
      </h3>
    </div>
    <div css={progressBarContainer}>
      <div css={progressBarFiller(completion)}></div>
    </div>
  </div>
);

const WriteProgressBar = ({ completions, stage, setStage }) => {
  const navigate = useNavigate();
  const asset = useRecoilValue(assetState);

  useEffect(() => {
    // console.log('assetState:', asset);
  }, [asset]);


  const stageChanger = (value) => {
    if (value < stage) {
      setStage(value);
    }
  }

  return (
    <div
      css={css`
        display: flex;
        flex-direction: row;
        margin-top: 60px;
        align-items: center;
        width: 90%;
        justify-content: space-between;
        color: white;
      `}
    >
      {stage > 1 ? (<img
        src={LeftArrow}
        onClick={() => setStage(stage - 1)}
        css={css`
          width: 30px;
          height: 30px;
          margin-right: 60px;
          cursor: pointer;
        `}
        alt="Go back"
      />) : ''}
      <ProgressItem number={1} label="증권 형태 선택" completion={completions[0]} stage={stage} stageChanger={stageChanger}/>
      <ProgressItem number={2} label="기본 정보 입력" completion={completions[1]} stage={stage} stageChanger={stageChanger}/>
      <ProgressItem number={3} label="세부 정보 입력" completion={completions[2]} stage={stage} stageChanger={stageChanger}/>
    </div>
  );
};

const progressBarContainer = css`
  width: 100%;
  height: 5px;
  background-color: #ccc;
  margin-top: 2px;
`;

const progressBarFiller = (completion) => css`
  width: ${completion}%;
  height: 100%;
  background-color: #728FD2;
  transition: width 0.5s ease-in-out;
`;

const progressItem = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin-right: 20px;
  cursor: pointer;
`;

const progressTop = css`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
export default WriteProgressBar;
