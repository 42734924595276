/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';
import { css } from '@emotion/react';
import ApiService from "services/ApiService";
import { useNavigate, useSearchParams } from "react-router-dom";
import Input from "components/common/Input";

const commonWrapStyle = css`
  min-width: 800px;
  min-height: 100vh;
  padding: 30px 40px;
  background: linear-gradient(to top right,
  rgba(0, 0, 0, 1) 0%,
  rgba(4, 4, 6, 1) 25%,
  rgba(15, 26, 54, 1) 50%,
  rgba(22, 38, 64, 0.77) 75%,
  rgba(55, 103, 163, 1) 100%);
  color: white;
`;

const sectionHeaderStyle = css`
  color: white;
  padding: 10px 20px;
  font-size: 18px;
  font-weight: 500;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #525C67;
  }
`;

const tableStyle = css`
  width: 800px;
  margin: 40px;
  padding: 0px 20px;
  background-color: #191C22;
  border-radius: 12px;
  border-collapse: collapse;

  tr {
    height: 50px;
  }

  th {
    border-bottom: 0.3px solid #525C67;
  }

  th, td {
    border-right: 0.3px solid #525C67;
    padding: 0 10px;
  }

  th:last-child, td:last-child {
    border-right: none;
  }

  th:nth-of-type(1) {
    width: 22%;
  }

  th:nth-of-type(2),
  th:nth-of-type(3),
  th:nth-of-type(4),
  th:nth-of-type(5),
  th:nth-of-type(6),
  th:nth-of-type(7) {
    width: 13%;
  }

  td {
    padding-left: 10px;
  }
`;

const tableWrapStyle = css`
  color: white;
  border-radius: 10px;
  padding: 10px 20px;
  max-width: 700px;

  div:not(:last-of-type) {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    //height: 100px;
  }

  div:last-of-type {
    display: flex;
    justify-content: space-between;
    padding: 20px;
  }

  dt {
    color: #B5B5B5;
  }
`;

const blueTestStyle = css`
  color: #728FD2 !important;
`;

const paddingLeftSmallStyle = css`
  margin-left: 15px !important;
`;

const paddingLeftBigStyle = css`
  font-size: 15px;
  margin-left: 30px !important;
`;

const setContentCenter = css`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const disablePaddingStyle = css`
  padding: 0 !important;
  ${setContentCenter};
`;

function SecurityDoc({}) {
  const [searchParams] = useSearchParams();
  const [tokenData, setTokenData] = useState(null);
  const [tenantDataOne, setTenantDataOne] = useState('');
  const [tenantDataTwo, setTenantDataTwo] = useState('');
  const [tenantDataThree, setTenantDataThree] = useState('');
  const [tenantDataFour, setTenantDataFour] = useState('');

  const navigate = useNavigate();

  const calculateSecurityData = (tokenData) => {
    if (!tokenData) return null;

    return {
      // 물건명
      itemName: tokenData.asset_property.name,
      // 총공모금액
      totalOfferAmount: formatCurrency(tokenData.subscribe.requiredAmount),
      // 위탁수익금
      commissionIncome: formatCurrency(tokenData.modeling_result.fundingAmount[0].amt),
      // 발행제비용
      issueCosts: formatCurrency(tokenData.modeling_result.fundingAmount.slice(2, 10).reduce((sum, item) => sum + item.amt, 0)),
      // 부동산신탁수수료
      realEstateTrustFee: formatCurrency(tokenData.modeling_result.fundingAmount[2].amt),
      // 플랫폼상장수수료
      platformListingFee: formatCurrency(tokenData.modeling_result.fundingAmount[3].amt),
      // 제반부대비용
      incidentalExpenses: formatCurrency(tokenData.subscribe.requiredAmount - tokenData.modeling_result.fundingAmount[0].amt - tokenData.modeling_result.fundingAmount[2].amt - tokenData.modeling_result.fundingAmount[3].amt - tokenData.modeling_result.fundingAmount[10].amt),
      // 예비비
      reserveFunds: formatCurrency(tokenData.modeling_result.fundingAmount[10].amt),
      // 총모집수량
      totalCollectionAmount: formatCurrency(tokenData.subscribe.requiredAmount / tokenData.subscribe.dasPrice),
      // 모집총액
      collectionTotalAmount: formatCurrency(tokenData.subscribe.requiredAmount),
      // DAS당 모집가액(원)
      dasCollectionPrice: formatCurrency(tokenData.subscribe.dasPrice),
      // DAS당 액면가액(원)
      dasFaceValue: formatCurrency(tokenData.subscribe.dasPrice),
      // 비고
      remark: tokenData.subscribe.subscribeType,
      // 청약기일
      subscriptionPeriod: `${tokenData.subscribe.stdDate} ~ ${tokenData.subscribe.endDate}`,
      // 신탁사명
      trustCompanyName: tokenData.asset_trust.corpName,
      // 발행사명
      publisherName: tokenData.publisher.corpName,
      // 영문명
      publisherNameEn: tokenData.publisher.corpNameEn,
      // 대표자
      ceoName: tokenData.publisher.ceo,
      // 본접 사업자등록번호
      businessNumber: tokenData.publisher.businessNumber,
      // 본사 주소
      corporateAddress: tokenData.publisher.corpAddress,
      // 본사 전화번호
      corporateTel: tokenData.publisher.corpTel,
      // 인터넷 홈페이지 주소
      corporateHomepage: tokenData.publisher.corpHomepage,
      // 기업 규모
      corporateSize: tokenData.publisher.corpSize,
      // 결산월
      settlementMonth: tokenData.publisher.settlementMonth,
      // 업종명
      businessType: tokenData.publisher.businessType,
      // 회사설립일
      corporateEstablishmentDate: tokenData.publisher.corpEstateDate,
      // 임직원수
      numberOfEmployees: tokenData.publisher.num,
      // 부동산개요 - 위치
      propertyLocation: tokenData.asset_property.addressInfo.jibunAddr,
      // 부동산개요 - 대상
      propertyTarget: tokenData.request.asset.detailAddress,
      // 부동산개요 - 대지면적
      landArea: `${tokenData.asset_property.buildingRedge.titleInfo.platArea} 중 ${tokenData.request.asset.spec.landAreaExp}`,
      // 부동산개요 - 연면적
      totalFloorArea: tokenData.asset_property.buildingRedge.titleInfo.totArea,
      // 부동산개요 - 건물규모
      buildingScale: `전체 지하 ${tokenData.asset_property.buildingRedge.titleInfo.ugrndFlrCnt} 층 ~ 지상 ${tokenData.asset_property.buildingRedge.titleInfo.grndFlrCnt} 층 중 일부`,
      // 부동산개요 - 높이
      buildingHeight: tokenData.asset_property.buildingRedge.titleInfo.heit,
      // 부동산개요 - 건물구조
      buildingStructure: tokenData.asset_property.buildingRedge.titleInfo.strctCdNm,
      // 부동산개요 - 주차대수
      parkingCount: tokenData.asset_property.buildingRedge.titleInfo.totPkngCnt,
      // 부동산개요 - 사용승인일
      approvalDate: tokenData.asset_property.buildingRedge.titleInfo.useAprDay,
      // 부동산개요 - 용도지역
      zoningArea: tokenData.asset_property.buildingRedge.regionLocalDistrictInfo[0].jijiguCdNm,
      // 부동산개요 - 건물 주용도
      mainBuildingUsage: `${tokenData.asset_property.buildingRedge.titleInfo.mainPurpsCdNm} 전체: ${tokenData.asset_property.buildingRedge.titleInfo.etcPurps}`,
      // 임차인 개요 - 임차인
      tenantName: tokenData.request.asset.lease.tenant[0].name,
      // 임차인 개요 - 대표자명
      tenantRepresentative: tokenData.request.asset.lease.tenant[0].name,
      // 임차인 개요 - 주소
      tenantAddress: `${tokenData.request.asset.address} ${tokenData.request.asset.detailAddress}`,
      // 영업수익
      businessProfit: tokenData.modeling_result.profitRate.map(pr => ({
        profit: formatCurrency(pr.items[0].amt)
      })),
      // 영업비용
      businessCost: tokenData.modeling_result.profitRate.map(pr => ({
        cost: formatCurrency(pr.items[1].amt + pr.items[4].amt + pr.items[5].amt + pr.items[8].amt)
      })),
      // 영업이익
      businessIncome: tokenData.modeling_result.profitRate.map(pr => ({
        cost: formatCurrency(pr.netProfit)
      })),
      // 예상배당수익률
      baedangRate: tokenData.modeling_result.profitRate.map(pr => ({
        cost: formatCurrency(pr.profitRate)
      })),
    }
  };

  const formatCurrency = (amount) => {
    return amount.toLocaleString('en-US');
  };


  const securityData = calculateSecurityData(tokenData);

  useEffect(() => {
    const key = searchParams.get('key');
    if (key) {
      fetchData(key);
    }
  }, [searchParams]);

  const fetchData = async (key) => {
    try {
      const data = await ApiService.getInfo(key);
      setTokenData(data[0]);
    } catch (error) {
      console.error('Failed to fetch data:', error);
    }
  };

  return (
    <div css={commonWrapStyle}>
      {tokenData && (
        <>
        <span onClick={() => {
          navigate(-1)
        }} css={css`cursor: pointer;`}>뒤로가기</span>
          <h2>{securityData.itemName} 증권신고서</h2>
          <div css={css`padding: 20px 40px;
            width: 800px;`}>
            <div css={sectionHeaderStyle}>
              <span>총 공모금액</span>
            </div>
            <div css={tableWrapStyle}>
              <dl>
                <div>
                  <dt css={blueTestStyle}>총 공모금액
                  </dt>
                  <dd css={blueTestStyle}>{securityData.totalOfferAmount} 원
                  </dd>
                </div>
                <div css={paddingLeftSmallStyle}>
                  <dt>위탁수익금</dt>
                  <dd>{securityData.commissionIncome} 원</dd>
                </div>
                <div css={paddingLeftSmallStyle}>
                  <dt>발행제비용</dt>
                  <dd>{securityData.issueCosts} 원</dd>
                </div>
                <div css={paddingLeftBigStyle}>
                  <dt>부동산 신탁 수수료</dt>
                  <dd>{securityData.realEstateTrustFee} 원</dd>
                </div>
                <div css={paddingLeftBigStyle}>
                  <dt>플랫폼 상장 수수료</dt>
                  <dd>{securityData.platformListingFee} 원</dd>
                </div>
                <div css={paddingLeftBigStyle}>
                  <dt>제반 부대비용</dt>
                  <dd>{securityData.incidentalExpenses} 원</dd>
                </div>
                <div css={paddingLeftBigStyle}>
                  <dt>예비비</dt>
                  <dd>{securityData.reserveFunds} 원</dd>
                </div>

              </dl>
            </div>

            <div css={sectionHeaderStyle}>
              <span>모집조건</span>
            </div>
            <div css={css`display: flex;
              flex-direction: row;`}>
              <div css={tableWrapStyle}>
                <dl>
                  <div>
                    <dt>모집 DAS 수량</dt>
                    <dd>{securityData.totalCollectionAmount}좌</dd>
                  </div>
                  <div>
                    <dt>DAS당 모집가액(원)</dt>
                    <dd>{securityData.dasCollectionPrice}원</dd>
                  </div>
                  <div>
                    <dt>DAS당 액면가액(원)</dt>
                    <dd>{securityData.dasFaceValue}원</dd>
                  </div>
                </dl>
              </div>
              <div css={tableWrapStyle}>
                <dl>
                  <div>
                    <dt>모집총액</dt>
                    <dd>{securityData.collectionTotalAmount}원</dd>
                  </div>
                  <div>
                    <dt>비고</dt>
                    <dd>{securityData.remark}</dd>
                  </div>
                  <div>
                    <dt>청약기일</dt>
                    <dd>{securityData.subscriptionPeriod}</dd>
                  </div>
                </dl>
              </div>
            </div>

            <div css={sectionHeaderStyle}>
              <span>예상 배당수익률</span>
            </div>
            <div css={css`margin-top: 15px;
              float: right;
              font-size: 12px;
              color: #B3B3B3`}>(단위:천원)
            </div>
            <table css={tableStyle}>
              <thead>
              <tr>
                <th>사업기수</th>
                <th>최초결산월</th>
                <th>1기</th>
                <th>2기</th>
                <th>3기</th>
                <th>4기</th>
                <th>5기</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td>영업수익</td>
                {securityData.businessProfit.map((pr, index) => (
                  <td key={index}>{pr.profit}</td>
                ))}
              </tr>
              <tr>
                <td>영업비용</td>
                {securityData.businessCost.map((pr, index) => (
                  <td key={index}>{pr.cost}</td>
                ))}
              </tr>
              <tr>
                <td>영업이익</td>
                {securityData.businessIncome.map((pr, index) => (
                  <td key={index}>{pr.cost}</td>
                ))}
              </tr>
              <tr>
                <td>예상 배당수익률(연환산)</td>
                {securityData.baedangRate.map((pr, index) => (
                  <td key={index}>{pr.cost}%</td>
                ))}
              </tr>
              </tbody>
            </table>


            <div css={sectionHeaderStyle}>
              <span>신탁사</span>
            </div>

            <div css={tableWrapStyle}>
              <dl>
                <div>
                  <dt>신탁사명</dt>
                  <dd>{securityData.trustCompanyName}</dd>
                </div>
              </dl>
            </div>
            <div css={sectionHeaderStyle}>
              <span>발행사</span>
            </div>
            <div css={tableWrapStyle}>
              <dl>
                <div>
                  <dt>발행사명</dt>
                  <dd>{securityData.publisherName}</dd>
                </div>
                <div>
                  <dt>영문명</dt>
                  <dd>{securityData.publisherNameEn}</dd>
                </div>
                <div>
                  <dt>대표자</dt>
                  <dd>{securityData.ceoName}</dd>
                </div>
                <div>
                  <dt>본점 사업자등록번호</dt>
                  <dd>{securityData.businessNumber}</dd>
                </div>
                <div>
                  <dt>본사 주소</dt>
                  <dd>{securityData.corporateAddress}</dd>
                </div>
                <div>
                  <dt>본사 전화번호</dt>
                  <dd>{securityData.corporateTel}</dd>
                </div>
                <div>
                  <dt>인터넷 홈페이지 주소</dt>
                  <dd>{securityData.corporateHomepage}</dd>
                </div>
                <div>
                  <dt>기업규모</dt>
                  <dd>{securityData.corporateSize}</dd>
                </div>
                <div>
                  <dt>결산월</dt>
                  <dd>{securityData.settlementMonth}</dd>
                </div>
                <div>
                  <dt>업종명</dt>
                  <dd>{securityData.businessType}</dd>
                </div>
                <div>
                  <dt>회사설립일</dt>
                  <dd>{securityData.corporateEstablishmentDate}</dd>
                </div>
                <div>
                  <dt>임직원수</dt>
                  <dd>{securityData.numberOfEmployees}</dd>
                </div>
              </dl>
            </div>
            <div css={sectionHeaderStyle}>
              <span>부동산 개요</span>
            </div>
            <div css={tableWrapStyle}>
              <dl>
                <div>
                  <dt>위치</dt>
                  <dd>{securityData.propertyLocation}</dd>
                </div>
                <div>
                  <dt>대상</dt>
                  <dd>{securityData.propertyTarget}</dd>
                </div>
                <div>
                  <dt>대지면적</dt>
                  <dd>{securityData.landArea} (본 신탁재산 해당면적)</dd>
                </div>
                <div>
                  <dt>연면적</dt>
                  <dd>{securityData.totalFloorArea}</dd>
                </div>
                <div>
                  <dt>건물 규모</dt>
                  <dd>{securityData.buildingScale}</dd>
                </div>
                <div>
                  <dt>높이</dt>
                  <dd>{securityData.buildingHeight}</dd>
                </div>
                <div>
                  <dt>건물구조</dt>
                  <dd>{securityData.buildingStructure}</dd>
                </div>
                <div>
                  <dt>주차대수</dt>
                  <dd>{securityData.parkingCount}대</dd>
                </div>
                <div>
                  <dt>사용승인일</dt>
                  <dd>{securityData.approvalDate}</dd>
                </div>
                <div>
                  <dt>용도지역</dt>
                  <dd>{securityData.zoningArea}</dd>
                </div>
                <div>
                  <dt>건물 주용도</dt>
                  <dd>{securityData.mainBuildingUsage}</dd>
                </div>
                <div>
                  <dt>본 재산 주용도</dt>
                  <dd></dd>
                </div>
                <div>
                  <dt>사진</dt>
                  <dd></dd>
                </div>

              </dl>
            </div>

            <div css={sectionHeaderStyle}>
              <span>건물 입지 여건</span>
            </div>
            <div css={tableWrapStyle}>
              <dl>
                <div>
                  <dt>여건이</dt>
                  <dd>훌륭합니다</dd>
                </div>
              </dl>
            </div>


            <div css={sectionHeaderStyle}>
              <span>임차인 개요</span>
            </div>
            <div css={tableWrapStyle}>
              <dl>
                <div>
                  <dt>임차인</dt>
                  <dd>{securityData.tenantName}</dd>
                </div>
                <div>
                  <dt css={setContentCenter}>대표자명</dt>
                  <dd>
                    <Input
                      type={'text'}
                      width={200}
                      placeholder="대표자명을 입력해주세요"
                      value={tenantDataOne}
                      onChange={(e) => setTenantDataOne(e.target.value)}
                      wrapStyle={disablePaddingStyle}
                    /></dd>
                </div>
                <div>
                  <dt>주소</dt>
                  <dd>{securityData.tenantAddress}</dd>
                </div>
                <div>
                  <dt css={setContentCenter}>기업형태</dt>
                  <dd><Input
                    type={'text'}
                    width={200}
                    placeholder="ex.중소기업,중견기업,대기업 등"
                    value={tenantDataTwo}
                    onChange={(e) => setTenantDataTwo(e.target.value)}
                    wrapStyle={disablePaddingStyle}
                  /></dd>
                </div>
                <div>
                  <dt css={setContentCenter}>업종</dt>
                  <dd><Input
                    type={'text'}
                    width={200}
                    placeholder="ex.제약 바이오 장비 제조"
                    value={tenantDataThree}
                    onChange={(e) => setTenantDataThree(e.target.value)}
                    wrapStyle={disablePaddingStyle}
                  /></dd>
                </div>
                <div>
                  <dt css={setContentCenter}>자본금</dt>
                  <dd><Input
                    type={'text'}
                    width={200}
                    placeholder="ex.3억원(2021년 12월 31일 기준"
                    value={tenantDataFour}
                    onChange={(e) => setTenantDataFour(e.target.value)}
                    wrapStyle={disablePaddingStyle}
                  /></dd>
                </div>
              </dl>
            </div>

            <div css={sectionHeaderStyle}>
              <span>감정평가</span>
            </div>
            <div css={tableWrapStyle}>
              <dl>
                <div>
                  <dt>감정해봤는데</dt>
                  <dd>훌륭해요</dd>
                </div>
              </dl>
            </div>
          </div>
        </>
      )}

    </div>
  );


}

export default SecurityDoc;
