/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';
import { css } from '@emotion/react';
import ApiService from "services/ApiService";
import { useNavigate, useSearchParams } from "react-router-dom";

const commonWrapStyle = css`
  min-width: 800px;
  height: 100%;
  padding: 30px 40px;
  background: linear-gradient(to top right,
  rgba(0, 0, 0, 1) 0%,
  rgba(4, 4, 6, 1) 25%,
  rgba(15, 26, 54, 1) 50%,
  rgba(22, 38, 64, 0.77) 75%,
  rgba(55, 103, 163, 1) 100%);
  color: white;
`;

const detailWrapStyle = css`
  display: flex;
  flex-direction: column;
  padding: 80px 40px;
`;

const detailHeaderStyle = css`display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;

  button {
    border: 2px solid white;
    border-radius: 14px;
    padding: 10px 30px;
    background-color: inherit;
    width: 200px;
    color: white;
    font-size: 15px;
    font-weight: bold;
    margin-left: 20px;
    cursor: pointer;
  }
;
`


const containerStyle = css`
  background-color: #273149;
  color: white;
  border-radius: 10px;
  padding: 10px 20px;
  max-width: 700px;

  div:not(:last-of-type) {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    padding: 20px 20px;
    border-bottom: 1px rgba(82, 92, 103, 1) solid;
  }

  div:last-of-type {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    padding: 20px;
  }
`;

function TokenDetail({ backToList, detailKey }) {
  const [searchParams] = useSearchParams();
  const [tokenData, setTokenData] = useState(null);
  const navigate = useNavigate();
  const [key, setKey] = useState(null);

  useEffect(() => {
    const keyFromUrl = searchParams.get('key');
    if (keyFromUrl) {
      setKey(keyFromUrl);
    }
  }, [searchParams]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await ApiService.getInfo(key);
        setTokenData(data[0]);
      } catch (error) {
        console.error('Failed to fetch data:', error);
      }
    };

    if (key) {
      fetchData();
    }
  }, [key]);

  const downloadDocu = () => {
    ApiService.downloadStoDocument(key);
  }
  const renderDetail = () => {
    if (!tokenData) {
      return (
        <div css={commonWrapStyle}><p>데이터가 없습니다.</p></div>
      );
    }

    const divRate = tokenData?.modeling_result?.summary?.divRate ?? '정보 없음';
    const price = tokenData?.modeling_result?.summary?.price ?? '정보 없음';
    const requiredAmount = tokenData?.modeling_result?.summary?.requiredAmount ?? '정보 없음';
    const dasPrice = tokenData?.subscribe?.dasPrice ?? '정보 없음';
    const publishAmount = tokenData?.subscribe?.requiredAmount ?? '정보 없음';
    const publishDate = tokenData?.subscribe?.publishDate ?? '정보 없음';
    const assetName = tokenData?.request?.asset?.name ?? '정보 없음';

    return (
      <div css={commonWrapStyle}>
        <span onClick={backToList} css={css`cursor: pointer;`}>뒤로가기</span>
        <div css={detailHeaderStyle}>
          <h2>{assetName}</h2>
          <div>
            <button onClick={() => alert('아직')}>삭제</button>
            <button onClick={() => navigate('/?type=doc&key=' + key)}>증권신고서 상세</button>
          </div>
        </div>

        <div css={containerStyle}>
          <dl>
            <div>
              <dt>예상 <span css={css`color: #2C73A7`}>배당</span> 수익률</dt>
              <dd>연 {divRate}%</dd>
            </div>
            <div>
              <dt>건물 <span css={css`color: #2C73A7`}>매수</span> 예정금액</dt>
              <dd>{price}</dd>
            </div>
            <div>
              <dt>필요 모집 총액</dt>
              <dd>{requiredAmount}</dd>
            </div>
            <div>
              <dt>DAS당 모집가액</dt>
              <dd>{dasPrice}</dd>
            </div>
            <div>
              <dt>발행 수량</dt>
              <dd>{publishAmount}</dd>
            </div>
            <div>
              <dt>예정 발행일</dt>
              <dd>{publishDate}</dd>
            </div>
          </dl>
        </div>
      </div>
    );
  };

  return renderDetail();


  return (
    <div css={commonWrapStyle}>
      <renderDetail/>
    </div>
  );
}

export default TokenDetail;
