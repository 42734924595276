/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';
import { css } from '@emotion/react';
import Input from 'components/common/Input';
import Select from 'components/common/Select';
import { useRecoilState } from "recoil";
import { assetState, userState, initialAssetState } from "recoil/Atoms";
import ApiService from "services/ApiService";


const StockInfo = ({ title, content }) => (
  <div>
    <span>{title}</span>
    <em>{content}</em>
  </div>
);

const WriteStageThree = ({ setStage, setResultKey }) => {
  const [leaseFee, setLeaseFee] = useState('');
  const [rentalDeposit, setRentalDeposit] = useState('');
  const [stdDate, setStdDate] = useState('');
  const [expireDate, setExpireDate] = useState('');
  const [purposeCode, setPurposeCode] = useState('');
  const [purposeName, setPurposeName] = useState('');
  const [leaseName, setLeaseName] = useState('');
  const [user] = useRecoilState(userState);
  const [asset, setAsset] = useRecoilState(assetState);

  const [subOptions, setSubOptions] = useState([]);
  const [selectedSubCategory, setSelectedSubCategory] = useState('');

  const {
    typeName,
    name,
    address,
    detailAddress,
    requestPrice,
    spec: { landAreaExp, buildingAreaExp, buildingAreaPub }
  } = asset;

  const subCategoryOptions = {
    "주거용": ["주거시설", "준주택시설"],
    "상업용": ["제1종 근린생활시설", "제2종 근린생활시설", "숙박시설", "판매시설", "위락시설", "의료시설", "일반업무시설", "자동차시설", "공중위생시설"],
    "공업용": ["공장시설", "창고시설", "위험물저장 및 처리시설", "자원순환 관련시설"],
    "농수산용": ["축산시설", "수산시설", "농업시설"],
    "사회문화용": ["문화 및 집회시설", "종교시설", "운동시설", "교육연구시설", "노유자시설", "수련시설"],
    "공공용": ["공공업무시설", "교정 및 군사시설", "발전시설", "운수시설", "방송통신시설"],
    "그외": ["묘지관련시설", "장례시설", "관광휴게시설", "야영장시설"]
  };

  const handlePurposeCodeChange = (e) => {
    const selectedCode = e.target.value;
    setPurposeCode(selectedCode);
    setSubOptions(subCategoryOptions[selectedCode].map(option => ({ label: option, value: option })));
    setSelectedSubCategory('');
  };

  const handleSubCategoryChange = (e) => {
    setSelectedSubCategory(e.target.value);
  };

  useEffect(() => {
    if (asset.lease) {
      if (asset.lease.leaseFee) setLeaseFee(asset.lease.leaseFee);
      if (asset.lease.rentalDeposit) setRentalDeposit(asset.lease.rentalDeposit);
      if (asset.lease.stdDate) setStdDate(asset.lease.stdDate);
      if (asset.lease.expireDate) setExpireDate(asset.lease.expireDate);
    }
    if (asset.spec) {
      if (asset.spec.purposeCode) setPurposeCode(asset.spec.purposeCode);
      if (asset.spec.purposeName) setPurposeName(asset.spec.purposeName);
    }
  }, [asset]);

  const handleNextButtonClick = async (nextYn) => {
    try {
      if (nextYn) {
        const updatedAsset = {
          ...asset,
          lease: {
            ...asset.lease,
            leaseFee: leaseFee,
            rentalDeposit: rentalDeposit,
            stdDate: stdDate,
            expireDate: expireDate,
            tenant: [{
              name: leaseName,
            }],
          },
          spec: {
            ...asset.spec,
            purposeCode: purposeCode,
            purposeName: purposeName,
          },
        };
        const ret = await ApiService.insertAsset(updatedAsset, user)
        if (ret.key) {
          setResultKey(ret.key);
          setAsset(initialAssetState);
          localStorage.clear();
          setStage(4);
        }
      } else {
        setAsset({
          ...asset,
          lease: {
            ...asset.lease,
            leaseFee: leaseFee,
            rentalDeposit: rentalDeposit,
            stdDate: stdDate,
            expireDate: expireDate,
            tenant: {
              name: leaseName,
            },
          },
          spec: {
            ...asset.spec,
            purposeCode: purposeCode,
            purposeName: purposeName,
          },
        });
        alert('임시저장 되었습니다.')
      }
    } catch (error) {
      console.error('Error sending data:', error);
      alert('등록 실패 = ' + error.message)
    }
  };

  return (
    <div css={stageThreeWrapStyle}>
      <div css={leftSideStyle}>
        <div css={stockInfoWrapStyle}>
          <StockInfo title="증권 형태" content={typeName}/>
          <StockInfo title="증권 종류" content={typeName}/>
          <StockInfo title="토큰 이름" content={name}/>
          <StockInfo title="주소" content={`${address} ${detailAddress}`}/>
          <StockInfo title="예상 매수 금액" content={`${requestPrice}원`}/>
          <StockInfo title="대지면적(전유)" content={`${landAreaExp}㎡`}/>
          <StockInfo title="연면적(전유)/연면적(공유면적 포함)" content={`${buildingAreaExp}㎡/${buildingAreaPub}㎡`}/>
        </div>
      </div>
      <div css={rightSideStyle}>
        <span>STEP3. 부동산 자산의 세부 정보를 입력해주세요.</span>
        <div css={inputBoxStyle}>
          <Input
            label="임차인명"
            width={500}
            placeholder="현재 임차인의 회사명(이름) 입력해주세요."
            value={leaseName}
            onChange={(e) => setLeaseName(e.target.value)}
          />
        </div>
        <div css={inputBoxStyle}>
          <Input
            label="임대료(월)"
            width={500}
            placeholder="현재 임차인의 월 임대료를 입력해주세요."
            unit="원"
            value={leaseFee}
            onChange={(e) => setLeaseFee(e.target.value)}
          />
        </div>
        <div css={inputBoxStyle}>
          <Input
            label="보증금"
            width={500}
            placeholder="현재 임차인의 보증금을 입력해주세요."
            unit="원"
            value={rentalDeposit}
            onChange={(e) => setRentalDeposit(e.target.value)}
          />
        </div>
        <div css={inputBoxStyle}>
          <Input
            type={'date'}
            label="임대차 계약 시작기간"
            width={500}
            placeholder="yyyy.mm.dd ~ yyyy.mm.dd"
            value={stdDate}
            onChange={(e) => setStdDate(e.target.value)}
          />
          <Input
            type={'date'}
            label="임대차 계약 종료기간"
            width={500}
            placeholder="yyyy.mm.dd ~ yyyy.mm.dd"
            value={expireDate}
            onChange={(e) => setExpireDate(e.target.value)}
          />
        </div>
        <div css={inputBoxStyle}>
          <Select
            label="건축물 용도"
            options={[
              { label: '주거용', value: '주거용' },
              { label: '상업용', value: '상업용' },
              { label: '공업용', value: '공업용' },
              { label: '농수산용', value: '농수산용' },
              { label: '사회문화용', value: '사회문화용' },
              { label: '공공용', value: '공공용' },
              { label: '그외', value: '그외' }
            ]}
            value={purposeCode}
            onChange={handlePurposeCodeChange}
            width={300}
          />
        </div>
        <div css={inputBoxStyle}>
          <Select
            label="건축물 용도 세부 분류"
            options={subOptions}
            value={selectedSubCategory}
            onChange={handleSubCategoryChange}
            width={300}
          />
        </div>
        <div css={btnWrapStyle}>
          <div css={btnPrimaryStyle} onClick={() => {
            handleNextButtonClick(0)
          }}>임시저장
          </div>
          <div css={btnSecondaryStyle} onClick={() => {
            handleNextButtonClick(1)
          }}>다음
          </div>
        </div>
      </div>
    </div>
  );
};

const stageThreeWrapStyle = css`
  width: 100%;
  color: white;
  display: flex;
  flex-direction: row;
  padding: 50px 0 0 0;
  margin-left: 100px;

  span {
    font-size: 18px;
  }
`;

const leftSideStyle = css`
  width: 500px;
  margin-right: 50px;
`;

const stockInfoWrapStyle = css`
  display: flex;
  flex-direction: column;
  gap: 30px;

  div {
    display: flex;
    flex-direction: column;

    span {
      color: #b3b3b3;
      font-size: 12px;
      margin-bottom: 10px;
    }

    em {
      font-style: normal;
      font-size: 15px;
    }
  }
`;

const rightSideStyle = css`
  width: 100%;
`;

const inputBoxStyle = css`
  margin-bottom: 40px;
  margin-top: 80px;
`;

const btnWrapStyle = css`
  display: flex;
  flex-direction: row;
  justify-content: center;
  color: white;
  gap: 10px;
  margin-top: 20px;
  cursor: pointer;
`;

const btnStyle = css`
  padding: 20px 30px;
  border-radius: 10px;
`;

const btnPrimaryStyle = css`
  ${btnStyle};
  background-color: rgba(44, 57, 71, 1);
`;

const btnSecondaryStyle = css`
  ${btnStyle};
  background-color: rgba(49, 111, 206, 1);
`;

export default WriteStageThree;
