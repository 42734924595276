/** @jsxImportSource @emotion/react */
import React from 'react';
import { css } from '@emotion/react';
import SideIconWrite from 'assets/images/write/img_blue-check.png';
import { useNavigate } from 'react-router-dom';
import ApiService from "services/ApiService";

const centerStyle = css`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const WrapStyle = css`
  width: 100%;
  display: flex;
  flex-direction: column;
  color: white;
  align-items: center;
  justify-content: center;
`;

const buttonStyle = css`
  width: 100px;
  padding: 20px 30px;
  color: white;
  text-align: center;
  border-radius: 12px;
  cursor: pointer;
`;

const homeBtnStyle = css`
  ${buttonStyle};
  background-color: #0a2049;
`;

const tokenBtnStyle = css`
  ${buttonStyle};
  background-color: #316fce;
`;

const WriteStageDone = ({ resultKey }) => {
  const navigate = useNavigate();

  const downloadDocu = () => {
    ApiService.downloadStoDocument(resultKey);
  }

  return (
    <div css={centerStyle}>
      <div css={WrapStyle}>
        <img
          css={css`
            width: 100px;
            margin-bottom: 30px;
          `}
          src={SideIconWrite}
          alt={'logo'}
        />
        <div
          css={css`
            font-size: 30px;
            font-weight: 700;
            margin-bottom: 60px;
          `}
        >
          토큰 발행 준비가 완료되었습니다. {resultKey}
        </div>
        <div
          css={css`
            display: flex;
            flex-direction: row;
            gap: 30px;
          `}
        >
          <span
            css={homeBtnStyle}
            onClick={() => {
              navigate('/');
            }}
          >
            홈으로 이동
          </span>
          <span css={tokenBtnStyle} onClick={() => {
            navigate('/?key='+resultKey);
          }}>토큰 관리하기</span>
        </div>
      </div>
    </div>
  );
};

export default WriteStageDone;
