/** @jsxImportSource @emotion/react */
import React from 'react';
import { css } from '@emotion/react';

function Input({ type, label, placeholder, width, unit, value, onChange, wrapStyle }) {

  const inputWrapStyle = css`
    padding: 10px 0;
    ${wrapStyle};
  `;

  const inputStyle = css`
    border-radius: 10px;
    background-color: #323b51;
    padding-left: 10px;
    color: #FFFFFF;
    width: ${width}px;
    height: 50px;
    font-size: 17px;
    border: none;


    &::placeholder {
      color: #7c7c7c;
    }

    &:focus {
      outline: solid 1px #D5D5D5;
    }
  `;

  const labelStyle = css`
    color: #ffffff;
    font-size: 18px;
    display: block;
  `;

  const unitStyle = css`
    color: #ffffff;
    font-size: 20px;
    margin-left: 10px;
    display: inline-block;
  `;

  return (
    <div css={inputWrapStyle}>
      {label && <label css={labelStyle}>{label}</label>}
      <div css={css`display: flex;
        align-items: center; padding: 0 !important;`}>
        <input
          type={type}
          placeholder={placeholder}
          css={inputStyle}
          value={value}
          onChange={onChange}
        />
        {unit && <span css={unitStyle}>{unit}</span>}
      </div>
    </div>
  );
}

export default Input;
