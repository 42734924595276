/** @jsxImportSource @emotion/react */
import React from 'react';
import { css } from '@emotion/react';
import { useRecoilValue } from 'recoil';
import { tokenListState } from 'recoil/Atoms';
import { useNavigate } from "react-router-dom";

const dashboardWrap = css`
  width: 100%;
  height: 100%;
  padding: 30px 20px;
  background: linear-gradient(to top right,
  rgba(0, 0, 0, 1) 0%,
  rgba(4, 4, 6, 1) 25%,
  rgba(15, 26, 54, 1) 50%,
  rgba(22, 38, 64, 0.77) 75%,
  rgba(55, 103, 163, 1) 100%);
`;

const dashBoardItem = css`
  border-radius: 10px;
  padding: 10px 20px;
  background-color: rgba(45, 46, 52, 0.5);
  color: white;
  margin: 15px 5px;
  cursor: pointer;
`;

const tableStyle = css`
  width: 100%;
  border-colapse: separate;
  border-spacing: 0 10px;

  th {
    padding: 15px 20px;
    text-align: left;
    font-weight: normal;
    font-size: 12px;
  }

  th:nth-of-type(1) {
    width: 10%;
  }

  th:nth-of-type(2) {
    width: 20%;
  }

  th:nth-of-type(3) {
    width: 30%;
  }

  th:nth-of-type(4) {
    width: 10%;
  }

  th:nth-of-type(5) {
    width: 15%;
  }
  td {
    padding: 15px 20px;
    background-color: rgba(255, 255, 255, 0.05);
    font-size: 15px;
    font-weight: 400;
  }

  td:first-of-type {
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
  }

  td:last-of-type {
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
  }

  th,
  td {
    width: 20%;
  }
`;

const judgeBaseStyle = css`
  padding: 10px 30px;
  background-color: #021230 !important;
  border-radius: 10px;
`;

const passStyle = css`
  ${judgeBaseStyle};
  color: #728FD2;
`;

const failStyle = css`
  ${judgeBaseStyle};
  color: #F5586A;
`;

function TokenList({ detailRender }) {
  const tokenList = useRecoilValue(tokenListState);
  const navigate = useNavigate();
  const profitJudgeNum = 5;
  const renderTokenList = (item) => {
    const formatDate = (dateString) => {
      return dateString ? dateString.substring(0, 10) : '';
    };

    const formatProfitRate = (profitRate) => {
      return profitRate !== undefined ? profitRate.toFixed(2) : '';
    };

    const workedAt = item.status_history && item.status_history.length > 0 ? formatDate(item.status_history[0].workedAt) : '';
    const assetName = item.request && item.request.asset && item.request.asset.name ? item.request.asset.name : '';
    const assetAddress = item.request && item.request.asset && item.request.asset.address ? item.request.asset.address : '';
    const profitRate = item.modeling_result && item.modeling_result.profitRate && item.modeling_result.profitRate.length > 0 ? formatProfitRate(item.modeling_result.profitRate[0].profitRate) : '';
    const judgeResult = item.curr_status;

    const profitRateResult = judgeResult === 'PASS' ? 'PASS' : 'FAIL';
    const judgeStyle = judgeResult === 'PASS' ? passStyle : failStyle;

    const moveToDetail = (key) => {
      detailRender(key);
    }


    return (
      <tr key={item.key} onClick={() => {
        moveToDetail(item.key)
      }}>
        <td>{workedAt}</td>
        <td>{assetName}</td>
        <td>{assetAddress}</td>
        <td><span css={judgeStyle} style={{ width: '100px' }}>{profitRateResult}</span></td>
        <td>{profitRate ? '연 ' : ''}{profitRate}</td>
      </tr>
    );
  };

  return (
    <div css={dashboardWrap}>
      {tokenList.length > 0 ? (
        <div css={dashBoardItem}>
          <h3>토큰 발행 현황</h3>
          <table css={tableStyle}>
            <thead>
            <tr>
              <th>등록 날짜</th>
              <th>토큰 이름</th>
              <th>주소</th>
              <th>필터링 결과</th>
              <th>예상 수익률</th>
            </tr>
            </thead>
            <tbody>{tokenList.slice(0, 10).map(renderTokenList)}</tbody>
          </table>
        </div>
      ) : (
        <div css={dashBoardItem}>
          <h3>토큰 발행 현황</h3>
          <p>현재 발행된 토큰이 없습니다.</p>
        </div>
      )}
    </div>
  );
}


export default TokenList;
